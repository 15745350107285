<template>
    <div @click="modalOpen = true">
        <!-- <input type="text" @change="save" v-model="value" class="bg-transparent"> -->
        <div class="truncate print:whitespace-normal" v-html="value" />
        <Modal v-show="modalOpen" :active="modalOpen" @hide="modalOpen = false" maxWidth="max-w-4xl !p-4">
            <h2 class="text-center uppercase text-sm font-semibold">{{ label }}</h2>
            <textarea rows="15" v-model="value" @change="save" class="" />
        </Modal>
    </div>
</template>

<script>
import Modal from '../../../components/layout/modal.vue'
import Tiny from '@tinymce/tinymce-vue'
import {useModel} from '../../model'

// Save state on modal open and check to see if it changed

export default {
    components: { Modal, Tiny },
    inject: ['darkMode'],
    props: ['modelValue','fieldModel','label'],
    data() {
        return {
            modalOpen: false,
            changed: false,
        }
    },
    watch: {
        modalOpen() {
            if (this.modalOpen) {
                this.changed = false
            } else {
                if (this.changed) {
                    this.save()
                }
            }
        }
    },
    methods: {
        save() {
            this.$emit('valueToSubmit', this.value)
        },
    },
	setup(props, { emit }) {
		return {
            value: useModel(props, emit, 'modelValue'),
		}
	},
}
</script>

<style>

</style>
