<template>
    <div class="w-full mb-16">
        <div v-if="data" class="flex flex-col items-center w-full">
            <div class="w-full max-w-7xl flex justify-between space-x-4">
                <div class="flex space-x-4 items-center mb-8 text-xs">
                    <button class="px-3 py-2 rounded border" :class="stat == 'KTWC' ? 'border-blue-500 text-blue-500 bg-blue-50/50' : ''" @click="stat = 'KTWC'">KTWC</button>
                    <button class="px-3 py-2 rounded border" :class="stat == 'Conversion' ? 'border-blue-500 text-blue-500 bg-blue-50/50' : ''" @click="stat = 'Conversion'">Conversion</button>
                    <button v-if="admin || ['branch','rep'].includes(type)" class="px-3 py-2 rounded border" :class="stat == 'Revenue' ? 'border-blue-500 text-blue-500 bg-blue-50/50' : ''" @click="stat = 'Revenue'">Revenue</button>
                    <button v-if="type == 'company'" class="px-3 py-2 rounded border" :class="stat == 'Reps/Branches' ? 'border-blue-500 text-blue-500 bg-blue-50/50' : ''" @click="stat = 'Reps/Branches'">Reps/Branches</button>
                    <button v-if="type == 'branch'" class="px-3 py-2 rounded border" :class="stat == 'Reps' ? 'border-blue-500 text-blue-500 bg-blue-50/50' : ''" @click="stat = 'Reps'">Reps</button>
                </div>
                <div class="text-xs">
                    <select v-model="chartSpan" class="border rounded pl-3 pr-6 py-2">
                        <option value="weeks">Last 2 Weeks</option>
                        <option value="month">Last Month</option>
                        <!-- <option value="quarter">Last Quarter</option> -->
                        <!-- <option value="year">Last Year</option> -->
                    </select>
                </div>
            </div>
            <div class="w-full max-w-7xl mx-auto relative">
                <Line :data="data" :options="chartOptions" class="min-h-[500px]" />
                <!-- <div v-if="loading" class="absolute z-50 inset-0 bg-black/10 backdrop-blur-[2px] flex items-center justify-center">
                    <div class="text-6xl 2xl:text-8xl font-black uppercase text-white">Loading</div>
                </div> -->
            </div>
        </div>
        <div v-else class="w-full max-w-7xl animate-pulse mx-auto">
            <div class="w-full flex justify-between mb-4">
                <div class="w-1/3 rounded-lg bg-gray-100 h-8" />
                <div class="w-28 rounded-lg bg-gray-100 h-8" />
            </div>
            <div class="w-full rounded-xl min-h-[500px] bg-gray-100" />
        </div>
        <!-- <Spinner v-else class="w-16 h-16 mx-auto" :small="true" /> -->
    </div>
</template>

<script>
import dayjs from 'dayjs'

let numberFields = [
    'knock',
    'talk',
    'walk',
    'contingency',
    'approvals',
    'contracts',
    'revenue',
    'softSets',
    'salesOpportunities',
]

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line, Pie } from 'vue-chartjs'
import latestCutoff from '../../helpers/latestCutoff'
import Mango from '../../helpers/mango'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default {
    components: {Line, Pie},
    props: {type: {default: 'company'}},
    inject: ['store'],
    data() {
        return {
            stat: 'KTWC',
            reports: null,
            chartSpan: 'weeks',
            loading: false,
        }
    },
    watch: {
        chartSpan: {
            handler: 'getChartReports',
            immediate: true
        },
    },
    methods: {
        async getChartReports() {
            this.loading = true
            let rangeMap = {
                weeks: 14,
                month: 30,
                quarter: 90,
                year: 365
            }
            let limitMap = {
                weeks: 12,
                month: 24,
                quarter: 72,
                year: 313
            }
            let range = rangeMap[this.chartSpan]
            let limit = limitMap[this.chartSpan]

            let fields = [...numberFields, 'created', 'collection']
            if (this.type == 'company') fields = [...fields, 'activeBranches', 'activeReps']
            if (this.type == 'branch') fields = [...fields, 'absentReps', 'totalReps', 'activeReps']

            let search = ''
            if (this.type == 'branch') search =  `branch: "${this.$route.params?.branchId}",`
            if (this.type == 'rep') search =  `rep: "${this.$route.params?.id}",`

            let gql = `
            query {
                ${this.type}Reports(
                    search: {${search} compareCreated: {greaterThan: ${latestCutoff()*1 - (range*24*60*60*1000)}, lessThan: ${latestCutoff(dayjs().add(2, 'days'))*1}}},
                    limit: ${limit},
                    sort: {created: -1}
                ) {
                    id
                    ${fields.join('\n')}
                }
            }`

            this.reports = (await Mango.graphql(gql))?.[`${this.type}Reports`]?.sort((a,b) => a.created - b.created)
            this.loading = false
        },
    },
    computed: {
        admin() {
            return this.store?.user?.roles?.includes('admin')
        },
        ylabel() {
            if (this.stat == 'KTWC') return
            if (this.stat == 'Conversion') return 'Percentage'
            if (this.stat == 'Revenue') return 'Dollars'
        },
        chartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        title: {
                            text: this.ylabel,
                            display: !!this.ylabel
                        }
                    }
                }
            }
        },
        data() {
            if (!this.reports?.length) return
            let datasets
            if (this.stat == 'KTWC') {
                datasets = [
                    {
                        label: 'Knock',
                        backgroundColor: 'red',
                        data: this.reports.map(r => r.knock)
                    },
                    {
                        label: 'Talk',
                        backgroundColor: 'blue',
                        data: this.reports.map(r => r.talk)
                    },
                    {
                        label: 'Walk',
                        backgroundColor: 'orange',
                        data: this.reports.map(r => r.walk)
                    },
                    {
                        label: 'Contingency',
                        backgroundColor: 'green',
                        data: this.reports.map(r => r.contingency)
                    },
                ]
            } else if (this.stat == 'Conversion') {
                datasets = [
                    {
                        label: 'C/K%',
                        backgroundColor: 'red',
                        data: this.reports.map(r => (((r.contingency) / (r.knock)) * 100)||0)
                    },
                    {
                        label: 'C/T%',
                        backgroundColor: 'orange',
                        data: this.reports.map(r => (((r.contingency) / (r.talk)) * 100)||0)
                    },
                    {
                        label: 'C/W%',
                        backgroundColor: 'yellow',
                        data: this.reports.map(r => (((r.contingency) / (r.walk)) * 100)||0)
                    },
                    {
                        label: 'W/K%',
                        backgroundColor: 'green',
                        data: this.reports.map(r => (((r.walk) / (r.knock)) * 100)||0)
                    },
                    {
                        label: 'W/T%',
                        backgroundColor: 'blue',
                        data: this.reports.map(r => (((r.walk) / (r.talk)) * 100)||0)
                    },
                    {
                        label: 'T/K%',
                        backgroundColor: 'purple',
                        data: this.reports.map(r => (((r.talk) / (r.knock)) * 100)||0)
                    }
                ]
            } else if (this.stat == 'Revenue') {
                datasets = [
                    {
                        label: 'Revenue',
                        backgroundColor: 'green',
                        data: this.reports.map(r => r.revenue)
                    }
                ]
            } else if (this.stat == 'Reps/Branches') {
                datasets = [
                    {
                        label: 'Active Reps',
                        backgroundColor: 'green',
                        data: this.reports.map(r => r.activeReps)
                    },
                    {
                        label: 'Active Branches',
                        backgroundColor: 'blue',
                        data: this.reports.map(r => r.activeBranches)
                    }
                ]
            } else if (this.stat == 'Reps') {
                datasets = [
                    {
                        label: 'Active Reps',
                        fill: true,
                        backgroundColor: 'green',
                        data: this.reports.map(r => r.activeReps)
                    },
                    {
                        label: 'Inactive Reps',
                        backgroundColor: 'red',
                        data: this.reports.map(r => r.absentReps)
                    },
                    {
                        label: 'Total Reps',
                        backgroundColor: 'blue',
                        data: this.reports.map(r => r.totalReps)
                    }
                ]
            }
            return {
                labels: this.reports.map(r => dayjs(r.created).format('ddd MM/DD')),
                datasets
            }

        }
    }
}
</script>

<style>

</style>
