<template>
    <button @click="modalOpen = true" class="w-full block text-left">
        <!-- <input type="text" @change="save" v-model="value" class="bg-transparent"> -->
        <People :people="value" size="w-8 h-8" space="-space-x-2" />
        <div v-if="value?.length == 1" class="pl-2">{{ value[0]?.title }}</div>
        <Modal v-show="modalOpen" :active="modalOpen" @hide="save" :allowOverflow="true">
            <h2 class="text-center uppercase text-sm font-semibold">{{ label }}</h2>
            <MultiSelect
                v-model:modelSelectedEntries="value"
                :create="false"
                :collection="fieldModel.relationship"
                :multiple="true"
                :key="entry.id"
                inputStyle=""
                class="border rounded-lg p-4"
            />
        </Modal>
    </button>
</template>

<script>
import Modal from '../../../components/layout/modal.vue'
import People from '../../../components/layout/people.vue'
import {useModel} from '../../model'
import MultiSelect from '../../multiSelect.vue'

export default {
  components: {MultiSelect, Modal, People},
    props: ['modelValue','fieldModel','entry','label'],
    data() {
        return {
            modalOpen: false,
        }
    },
    // watch: {
    //     valueToSubmit() {
    //         this.$emit('valueToSubmit', this.valueToSubmit)
    //     }
    // },
	setup(props, { emit }) {
		return {
            value: useModel(props, emit, 'modelValue'),
		}
	},
    methods: {
        save() {
            this.modalOpen = false
            this.$emit('valueToSubmit', this.valueToSubmit)
        }
    },
    computed: {
        valueToSubmit() {
            return this.value?.map(v => v.id)
        }
    }
}
</script>

<style>

</style>
