<template>
    <input type="text" @change="save" v-model="value" class="bg-transparent">
</template>

<script>
import {useModel} from '../../model'

export default {
    props: ['modelValue'],
    methods: {
        save() {
            this.$emit('valueToSubmit', this.value)
        },
    },
	setup(props, { emit }) {
		return {
            value: useModel(props, emit, 'modelValue'),
		}
	},
}
</script>

<style>

</style>
