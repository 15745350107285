<template>
    <div :class="{'dark':darkMode.enabled && false}">
        <div
            @click="$refs.nav.showMenu = false"
            class="relative from-white to-slate-50 bg-gradient-to-tr w-full min-h-screen
            dark:from-black dark:to-gray-900 dark:selection:bg-gray-400 dark:text-gray-400
            print:from-white print:to-white print:text-black"
        >

            <Nav :hidden="navHidden" ref="nav"/>

            <div class="w-full flex" v-if="store.user?.id && (store.user?.level > 1)">
                <Hamburger :hidden="hamburgerHidden" class="fixed left-0 z-[60] md:sticky shrink-0" />
                <div class="w-full shrink 2xl:overflow-visible sm:max-w-[calc(100vw-16rem)]">
                    <RouterView v-slot="{ Component, route }" @navHidden="navHidden = $event" @hamburgerHidden="hamburgerHidden = $event" class="w-full">
                        <!-- <KeepAlive v-if="route.path.includes('resources')">
                            <Suspense :timeout="200">
                                <Component :is="Component" :key="route.meta.usePathKey ? route.path : undefined" />
                                <template #fallback><spinner /></template>
                            </Suspense>
                        </KeepAlive> -->
                        <Suspense :timeout="200" >
                            <Component :is="Component" :key="route.meta?.keyed === false ? '' : route.path" />
                            <template #fallback><spinner /></template>
                        </Suspense>
                    </RouterView>
                </div>
            </div>

            <div v-else class="w-full h-screen flex flex-col items-center justify-center">
                <Login :small="true" class="!w-auto" :showLogo="true" />
                <div v-if="store.user?.id" class="mt-16 w-full max-w-2xl text-center leading-loose">
                    <div>You must be logged in as an
                    <span class="px-2 py-1 bg-red-300 border rounded-lg border-red-500 text-white font-semibold font-mono">admin</span>
                    or
                    <span class="px-2 py-1 bg-purple-300 border rounded-lg border-purple-500 text-white font-semibold font-mono">branchManager</span>
                    to access this dashboard.</div>
                </div>
            </div>

            <!-- <Footer class="absolute bottom-0" v-show="!navHidden" /> -->

        </div>
    </div>
</template>

<script>
import Nav from './components/layout/nav.vue'
import Hamburger from './components/layout/hamburger.vue'
import Login from './components/layout/login.vue'

export default {
    components: {Nav, Hamburger, Login},
    inject: ['store','darkMode'],
    data() {
        return {
            navHidden: false,
            hamburgerHidden: false,
        }
    }
}
</script>

<style lang="postcss">
@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');
/* Reset default styles for the select element */
/* Reset default styles for the select element */
select {
  appearance: none; /* Remove default appearance on some browsers */
  -webkit-appearance: none; /* Remove default appearance on Safari */
  -moz-appearance: none; /* Remove default appearance on Firefox */
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0px center; /* Adjust the position of the arrow */
}

/* Apply the filter utility to change SVG color in dark mode */
.dark select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgba(255, 255, 255, 0.5)' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
}
</style>
