<template>
<div class="relative">
    <div class="flex flex-col min-w-full text-sm " ref="container">

        <div class="flex shrink-0 divide-x divide-gray-300 dark:divide-gray-700">
            <!-- <div v-for="fieldName, header in headers" :key="header" class="px-3 py-2">{{ header }}</div> -->
            <Resizable
                v-for="fieldName, header in headers"
                :key="`col-${fieldName}`"
                :columnId="`--table${collectionName}-column${fieldName}-width`"
                :container="$refs.container"
                class="px-3 py-2 flex items-center bg-gray-200 dark:bg-gray-800 uppercase text-xs font-semibold shrink-0"
            >
                <div class="truncate">{{ header }}</div>
                <i @click="sort(fieldName)" class="fas ml-2 cursor-pointer" :class="[`fa-caret-${sortDirection}`, sortParam != fieldName ? 'text-grey-200 dark:text-gray-700' : 'text-gray-900 dark:text-gray-400']" />
            </Resizable>
            <div class="px-3 py-2 flex items-center bg-gray-200 dark:bg-gray-800 uppercase text-xs font-semibold shrink-0 w-20 print:hidden">Manage</div>
        </div>


        <div
            v-for="entry in entries" :key="entry.id"
            class="flex shrink-0 divide-x dark:divide-gray-700 overflow-visible"
        >
            <Cell
                v-for="fieldName, label in headers"
                class="px-3 py-2 truncate shrink-0 border-t dark:border-gray-700"
                :class="cellStyles(entry)"
                :collectionModel="collectionModel"
                :fieldName="fieldName"
                :key="fieldName"
                :entry="entry"
                :label="label"
                v-model="entry[fieldName]"
                @saving="saving[entry?.id] = $event"
                @update:entry="this.$emit('update:entry', $event)"
                :style="`width: var(--table${collectionName}-column${fieldName}-width);`"
            />
            <div
                class="px-3 py-2 truncate shrink-0 border-t dark:border-gray-700 w-20 flex justify-around items-center print:hidden"
                :class="cellStyles(entry)"
            >
                <button @click="trash(entry)"><i class="fas fa-trash-alt text-red-500 dark:text-red-800" /></button>
                <a target="_blank" :href="`/${collection}/${entry.id}`"><i class="far fa-external-link-alt text-blue-500 dark:text-blue-800" /></a>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Swal from 'sweetalert2'
import collections from '../../../../config/config/.collections.json'
import Cell from './cell.vue'
import Resizable from './resizable.vue'

export default {
  components: { Cell, Resizable },
    props: {collection: String, entries: Array, fields: Object},
    data() {
        return {
            saving: {},
            sortParam: localStorage.getItem('tableSortField') || null,
            sortDirection: localStorage.getItem('tableSortDirection') || 'down',
        }
    },
    watch: {
        entries() {
            this.sort(this.sortParam, this.sortDirection)
        }
    },
    mounted() {
        this.sort(this.sortParam, this.sortDirection)
    },
    methods: {
        sort(fieldName, direction) {
            if (this.sortParam == fieldName) {
                this.sortDirection = this.sortDirection == 'down' ? 'up' : 'down'
            } else {
                this.sortParam = fieldName
                localStorage.setItem('tableSortField', fieldName)
                this.sortDirection = 'down'
            }
            if (direction) this.sortDirection = direction
            localStorage.setItem('tableSortDirection', this.sortDirection)
            this.entries.sort((a,b) => {
                if (a[this.sortParam] < b[this.sortParam]) return this.sortDirection == 'down' ? -1 : 1
                if (a[this.sortParam] > b[this.sortParam]) return this.sortDirection == 'down' ? 1 : -1
                return 0
            })
            this.$forceUpdate()
        },
        trash(entry) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('delete:entry', entry)
                }
            })
        },
        cellStyles(entry) {
            return {
                'opacity-40 pointer-events-none': this.saving?.[entry.id],
                'bg-green-200/30': entry.scale == 'keynote',
                'bg-family-at-church-orange/10': entry.scale == 'breakout',
                'bg-purple-200/30': entry.scale == 'interview',
                'bg-yellow-200/30': entry.scale == 'stage',
                'bg-sky-200/30': entry.scale == 'meeting',
                'bg-sky-300/30': entry.scale == 'private meeting',
                'bg-red-200/30': entry.scale == 'livestream',
                'bg-white dark:bg-black': entry.scale == 'housekeeping',
            }
        }
    },
    computed: {
        collectionModel() {
            let collectionModel = collections.find(c => c.name == this.collection)
            if (!collectionModel) console.error(`🥭 ${collection} is not a valid collection. (table.vue)`)
            return collectionModel
        },
        collectionName() {
            return this.collectionModel?.humanName?.trim()
        },
        headers() {
            if (Array.isArray(this.fields)) return this.fields.reduce((a,f) => {a[f]=f;return a}, {})
            if (!this.fields && this.entries?.length) return Object.keys(this.entries[0]).reduce((a,f) => {a[f]=f;return a}, {})
            return this.fields
        }
    }
}
</script>

<style>

</style>
