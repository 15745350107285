<template>
    <div>
        <Hero title="Branch Reports" class="border-b-4 cursor-pointer" @click="$router.push('/branchReports/view')" />

        <div class="w-full max-w-4xl mx-auto px-4 flex flex-col py-16 gap-8">

            <div v-if="!$route.params?.id" class="pb-8 border-b-2 w-full flex items-start gap-8">
                <div class="w-full max-w-xs" v-if="store?.user?.roles?.includes('admin') ">
                    <div class="mb-2 uppercase text-sm font-semibold tracking-2xl opacity-50">Select a Branch</div>
                    <MultiSelect collection="branches" v-model:selectedIds="branches" @selectedIds="$forceUpdate()" :multiple="false" placeholder="Search Branches" />
                </div>
                <div class="">
                    <div class="mb-2 uppercase text-sm font-semibold tracking-2xl opacity-50">Select a Date</div>
                    <input type="date" v-model="selectedDate" class="border-2 rounded px-3 py-[6px] text-sm" />
                </div>
            </div>

            <Mango
                v-slot="{data: reports, loading}"
                collection="branchReports" :id="this.$route.params?.id"
                :query="{
                    limit: 1000,
                    sort: {cretated: 1},
                    search: {
                        branch: branches,
                        compareCreated: {
                            // We have to create a day object so that CT is established
                            greaterThan: dayjs(selectedDate),
                            lessThan: dayjs(nextDay)
                        }
                    },
                    fields: [
                        'created',
                        'title',
                        'branch',
                        'knock',
                        'talk',
                        'walk',
                        'contingency',
                        'approvals',
                        'contracts',
                        'revenue',
                        'softSets',
                        'salesOpportunities',
                        'installationsStarted',
                        'startedInstallationsRevenue',
                        'installationsCompleted',
                        'estemates',
                        'mtd',
                        'tracking',
                        'weather.icon'
                    ]
                }"
            >
                <Spinner v-if="loading" />
                <div v-else-if="reports.id" class="space-y-8">
                    <BranchReport :key="reports.id" :report="reports" />
                </div>
                <div v-else-if="reports.length" class="space-y-8">
                    <BranchReport v-for="report in reports" :key="report.id" :report="report" />
                </div>
                <div v-else>
                    <div class="text-center text-xl opacity-20 font-bold">Nothing to show for this date</div>
                </div>
            </Mango>
        </div>

    </div>
</template>

<script>
import Hero from '../components/layout/hero.vue'
import MultiSelect from '../helpers/multiSelect.vue'
import BranchReport from '../components/layout/branch-report.vue'
import dayjs from 'dayjs'

let afterSevenThirtyFive = dayjs().hour() >= 19 && dayjs().minute() >= 35

export default {
	components: { Hero, MultiSelect, BranchReport },
    inject: ['store','dayjs'],
    data() {
        return {
            branches: this.store?.user?.branches?.map(b => b.id) || [],
            selectedDate: afterSevenThirtyFive ? dayjs().format('YYYY-MM-DD') : dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        }
    },
    computed: {
        nextDay() {
            return this.dayjs(this.selectedDate).add(1, 'day').format('YYYY-MM-DD')
        }
    }
}
</script>

<style>

</style>
