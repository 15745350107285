<template>
    <component
        :is="type"
        :entry="entry"
        :label="label"
        v-model="value"
        :fieldModel="fieldModel"
        @valueToSubmit="save($event)"
        class="flex items-center focus-within:outline outline-2 outline-sky-500 outline-offset-[-2px] cell"
    />
</template>

<script>
import {useModel} from '../model'
import PlainText from './fieldTypes/plainText.vue'
import RichText from './fieldTypes/richText.vue'
import DateTime from './fieldTypes/dateTime.vue'
import Select from './fieldTypes/select.vue'
import Relationship from './fieldTypes/relationship.vue'
import Scripture from './fieldTypes/scripture.vue'
import Image from './fieldTypes/image.vue'
import Mango from '../mango'

export default {
    components: { PlainText, DateTime, Select, Relationship, RichText, Image, Scripture },
    props: {collectionModel: Object, fieldName: String, label: String, modelValue: {}, entry: {}},
    data() { return { saving: false } },
	setup(props, { emit }) {
		return {
            value: useModel(props, emit, 'modelValue'),
		}
	},
    methods: {
        async save(value) {
            this.$emit('saving', true)
            let data = {[this.fieldName]: value}
            if (this.entry?.id) data.id = this.entry.id
            let entry = await Mango[this.collectionModel.name].save(data)
            this.$emit('update:entry', entry)
            this.$emit('saving', false)
        }
    },
    computed: {
        fieldModel() { return this.collectionModel.fields?.find(f => f.name == this.fieldName) },
        type() { return this.fieldModel?.instanceOf || (this.fieldModel?.type == 'String' ? 'PlainText' : this.fieldModel?.type) || 'PlainText' }
    }
}
</script>

<style>

</style>
