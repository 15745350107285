<template>
    <label class="flex flex-col justify-between h-full">
        <div v-if="value?.length" class="flex flex-wrap gap-2">
            <div
                v-for="s in value" :key="s" v-html="s"
                class="px-2 py-1 rounded-full bg-gray-200 dark:bg-gray-600 hover:bg-red-700 dark:hover:bg-red-900/70 hover:text-white cursor-pointer"
                @click="value.splice(index,1)"
            />
        </div>
        <input type="text" @change="save" v-model="input" class="bg-transparent outline-none w-full">
    </label>
</template>

<script>
import {useModel} from '../../model'

export default {
    props: ['modelValue'],
    data() {
        return {
            input: null
        }
    },
    methods: {
        save() {
            if (this.value?.includes(this.input)) return
            this.value.push(this.input)
            this.input = null
            this.$emit('valueToSubmit', this.value)
        },
    },
	setup(props, { emit }) {
		return {
            value: useModel(props, emit, 'modelValue'),
		}
	},
}
</script>

<style>

</style>
