<template>
    <div v-if="collectionModel" class="flex flex-col h-[calc(100vh-3.5rem)] w-screen md:w-[calc(100vw-16rem)] overflow-auto">

        <!-- <Hero :title="collectionModel.humanName" class="border-b-4 dark:border-gray-800" /> -->
        <div class="w-full flex justify-center py-8 md:py-16 border-b">
            <div class="text-3xl md:text-4xl xl:text-5xl font-serif">Manage Opportunities</div>
        </div>

        <Modal v-if="selectedPhoto" @hide="selectedPhoto = null" maxWidth="max-w-4xl !p-0">
            <img :src="selectedPhoto" class="w-full" />
        </Modal>

        <Modal v-if="editingOwner" @hide="saveOwnerInfo(editingOwner)">
            <div class="text-center text-lg">Edit Owner Information</div>
            <div class="gap-4 flex flex-col">
                <div class="flex space-x-4">
                    <input type="text" v-model="editingOwner.ownerInfo.firstName" placeholder="First Name">
                    <input type="text" v-model="editingOwner.ownerInfo.lastName" placeholder="Last Name">
                </div>
                <div class="flex flex-col space-y-1 w-full">
                    <input v-model="editingOwner.ownerInfo.phoneNumber" type="tel" class="px-3 py-2 border w-full" placeholder="Phone">
                </div>
                <div class="flex flex-col space-y-1 w-full">
                    <input v-model="editingOwner.ownerInfo.emailAddress" type="email" class="px-3 py-2 border w-full" placeholder="Email">
                </div>
            </div>
            <!-- <button @click="saveOwnerInfo(editingOwner)" class="px-3 py-2 rounded bg-red-500 text-white">Save</button> -->
        </Modal>

        <Modal v-if="editingRevenue" @hide="saveRevenue(editingRevenue)">
            <div class="text-center text-lg">Edit Revenue</div>
            <div class="gap-4 flex flex-col">
                <div class="flex items-center w-full space-x-1 justify-center">
                    <span>$</span><input v-model="editingRevenue.revenue" type="tel" class="px-3 py-2 border w-full max-w-48" placeholder="Revenue">
                </div>
            </div>
            <!-- <button @click="saveOwnerInfo(editingRevenue)" class="px-3 py-2 rounded bg-red-500 text-white">Save</button> -->
        </Modal>

        <div class="w-full flex flex-col items-center p-4 sm:p-8 sm:pb-0">

            <div class="w-full max-w-7xl flex flex-wrap flex-col sm:flex-row gap-3 sm:gap-y-4 sm:gap-x-6 mb-4 items-center text-xs border p-4 relative shadow-card mb-8">

                <button
                    @click="clearFilters"
                    class="text-red-500 text-center underline absolute -top-3 -right-2 bg-red-50 p-2
                    border rounded
                    sm:border-t-0 sm:border-r-0 sm:border-b sm:border-l
                    sm:rounded-none sm:top-0 sm:right-0 sm:rounded-bl
                ">
                    Clear Filters
                </button>

                <div v-if="admin || branches?.length > 1" class="overflow-visible w-full max-w-xs">
                    <div class="text-2xs uppercase tracking-widest font-semibold opacity-50 mb-1 sm:mb-2">Branch</div>
                    <MultiSelect
                        class="w-full rounded-xl flex bg-white bg-white text-xs md:text-sm"
                        :inputStyle="`w-full text-xs !rounded-none !border !px-2 !py-1`"
                        v-model:modelSelectedIds="filters.branch"
                        v-model:modelSelectedEntries="branchEntries"
                        :algoliaFilters="branchSearchFilters"
                        collection="branches"
                        :multiple="false"
                        :create="false"
                    />
                </div>

                <div class="overflow-visible w-full max-w-xs">
                    <div class="text-2xs uppercase tracking-widest font-semibold opacity-50 mb-1 sm:mb-2">Rep</div>
                    <MultiSelect
                        class="w-full rounded-xl flex bg-white bg-white text-xs md:text-sm"
                        :inputStyle="`w-full text-xs !rounded-none !border !px-2 !py-1`"
                        v-model:modelSelectedIds="filters.rep"
                        v-model:modelSelectedEntries="repEntries"
                        :algoliaFilters="repSearchFilters"
                        collection="members"
                        :multiple="false"
                        :create="false"
                    />
                </div>

                <div class="flex gap-4">
                    <div class="w-1/3">
                        <div class="text-2xs uppercase tracking-widest font-semibold opacity-50 mb-1 sm:mb-2">St. Number</div>
                        <input v-model="filters.streetNumber" placeholder="Number" />
                    </div>

                    <div class="flex-grow">
                        <div class="text-2xs uppercase tracking-widest font-semibold opacity-50 mb-1 sm:mb-2">Street</div>
                        <input v-model="filters.street" placeholder="Street" />
                    </div>
                </div>

                <div class="flex gap-4">
                    <div class="">
                        <div class="text-2xs uppercase tracking-widest font-semibold opacity-50 mb-1 sm:mb-2">City</div>
                        <input v-model="filters.city" placeholder="City" />
                    </div>

                    <div class="">
                        <div class="text-2xs uppercase tracking-widest font-semibold opacity-50 mb-1 sm:mb-2">Owner Last Name</div>
                        <input v-model="filters.owner" placeholder="Last Name" />
                    </div>
                </div>

                <div class="">
                    <div class="text-2xs uppercase tracking-widest font-semibold opacity-50 mb-1 sm:mb-2">Revenue</div>
                    <div class="flex items-center space-x-4">
                        <input v-model.number="filters.revenueMin" placeholder="Min" />
                        <input v-model.number="filters.revenueMax" placeholder="Max" />
                    </div>
                </div>

                <div class="">
                    <div class="text-2xs uppercase tracking-widest font-semibold opacity-50 mb-1 sm:mb-2">Estimate</div>
                    <div class="flex items-center space-x-4">
                        <input v-model.number="filters.estimateMin" placeholder="Min" />
                        <input v-model.number="filters.estimateMax" placeholder="Max" />
                    </div>
                </div>

                <div class="w-full sm:w-auto">
                    <div class="text-2xs uppercase tracking-widest font-semibold opacity-50 mb-1 sm:mb-2">Entry Date Range</div>
                    <div class="text-xs flex space-x-4 items-center">
                        <input type="date" v-model="filters.rangeStart" />
                        <span class="hidden md:block mx-2">to</span>
                        <input type="date" v-model="filters.rangeEnd" />
                    </div>
                </div>

                <div class="flex gap-4 justify-between md:justify-start w-full sm:w-auto">
                    <div class="">
                        <div class="text-2xs uppercase tracking-widest font-semibold opacity-50 mb-1 sm:mb-2">Signed</div>
                        <Toggle v-model="filters.contractSigned" :small="true" background="bg-gray-100 dark:bg-gray-700" />
                    </div>
                    <div class="">
                        <div class="text-2xs uppercase tracking-widest font-semibold opacity-50 mb-1 sm:mb-2">Accepted</div>
                        <Toggle v-model="filters.insuranceAccepted" :small="true" background="bg-gray-100 dark:bg-gray-700" />
                    </div>
                    <div class="">
                        <div class="text-2xs uppercase tracking-widest font-semibold opacity-50 mb-1 sm:mb-2">Started</div>
                        <Toggle v-model="filters.installationStarted" :small="true" background="bg-gray-100 dark:bg-gray-700" />
                    </div>
                    <!-- <div class="">
                        <div class="text-2xs uppercase tracking-widest font-semibold opacity-50 mb-1 sm:mb-2">Completed</div>
                        <Toggle v-model="filters.installationCompleted" :small="true" background="bg-gray-100 dark:bg-gray-700" />
                    </div> -->
                </div>

                <!-- <div class="flex-grow flex justify-end">
                    <button @click="exportOpportunities" class="rounded flex items-center space-x-2 underline text-blue-500"><span>Download Opportunities</span><i class="fal fa-download text-2xs" /></button>
                </div>

                <div v-if="downloading" class="absolute w-full h-full inset-0 bg-black/50 flex items-center justify-center backdrop-blur-[2px]">
                    <div class="text-2xl font-bold text-white">Downloading Opportunities</div>
                </div> -->

            </div>
        </div>

        <!-- <Table v-else collection="opportunities" :entries="data" :collectionModel="collectionModel" class="mx-auto" /> -->
        <div class="flex-grow w-full max-w-7xl mx-auto " :class="{border: !loading}">
            <Mango
                :collection="collectionModel.name"
                class="w-full"
                :class="{'opacity-40 pointer-events-none': working}"
                :id="null"
                :algoliaSearch="algoliaSearch"
                :algoliaFilters="algoliaFilters"
                :infinite="true"
                @update:data="setData"
                @update:loadingPage="loadingPage = $event"
                @update:loading="loading = $event"
                :suspend="true"
                :query="query"
            >
                <Spinner v-if="loading" />
                <div v-else class="">
                    <table class="border-collapse w-full max-w-7xl text-xs">
                        <thead class="z-20">
                            <tr class="">
                                <th class="border border-l-0 border-t-0 px-3 py-2 bg-gray-100 font-semibold sticky top-0 shadow-b-lg z-20">Rep(s)</th>
                                <th class="border border-t-0 px-3 py-2 bg-gray-100 font-semibold sticky top-0 shadow-b-lg z-20">Image</th>
                                <th class="border border-t-0 px-3 py-2 bg-gray-100 font-semibold sticky top-0 shadow-b-lg z-30 sticky left-0">Address</th>
                                <th class="border border-t-0 px-3 py-2 bg-gray-100 font-semibold sticky top-0 shadow-b-lg z-20">City</th>
                                <th class="border border-t-0 px-3 py-2 bg-gray-100 font-semibold sticky top-0 shadow-b-lg z-20">State</th>
                                <th class="border border-t-0 px-3 py-2 bg-gray-100 font-semibold sticky top-0 shadow-b-lg z-20">Owner</th>
                                <th class="border border-t-0 px-3 py-2 bg-gray-100 font-semibold sticky top-0 shadow-b-lg z-20">Status</th>
                                <th class="border border-t-0 px-3 py-2 bg-gray-100 font-semibold sticky top-0 shadow-b-lg z-20">Revenue</th>
                                <th class="border border-t-0 px-3 py-2 bg-gray-100 font-semibold sticky top-0 shadow-b-lg z-20">Started</th>
                                <!-- <th class="border border-t-0 px-3 py-2 bg-gray-100 font-semibold sticky top-0 shadow-b-lg z-20">Completed</th> -->
                                <th class="border border-r-0 border-t-0 px-3 py-2 bg-gray-100 font-semibold sticky top-0 shadow-b-lg z-20">Updated</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="o in data" :key="o.id" :class="colorStatusMap?.[o.status], {'opacity-40 pointer-events-none': opportunitiesBeingSaved.includes[o.id]}" class="relative group">
                                <td class="border border-l-0 px-2 py-1 truncate">
                                    <div class="group-hover:opacity-100 opacity-0 pointer-events-none absolute w-full h-full inset-0 bg-black/10 z-50 transition-opacity duration-400"></div>
                                    <button @click="repEntries = [o.author, ...(o?.shotgun||[])]; filters.rep = [o.author, ...(o?.shotgun||[])].map(o => o.id)" class="flex space-x-4 items-center">
                                        <People size="w-8 h-8" :people="[o.author, ...(o?.shotgun||[])]?.filter(r => !!r?.image)" />
                                        <span v-if="!o.shotgun?.length">{{ o.author.title }}</span>
                                        <span v-else>{{ [o.author, ...(o?.shotgun||[])].map(r => r.firstName).join('/') }}</span>
                                    </button>
                                </td>
                                <td class="border truncate"><div v-if="o?.selfie?.url" @click="selectedPhoto = o?.selfie?.url" :style="`background-image: url(${o?.selfie?.url})`" class="w-18 aspect-video bg-center bg-cover" /></td>
                                <td class="border px-2 py-1 truncate sticky left-0 z-20" :class="colorStatusMap?.[o.status] || `bg-gray-50`">
                                    <router-link :to="`/opportunities/${o.id}`" class="underline" >{{ o?.address?.address || '' }}</router-link>
                                </td>
                                <td class="border px-2 py-1 truncate"><button v-if="o?.address?.city" @click="filters.city = o.address.city">{{ o?.address?.city || '' }}</button></td>
                                <td class="border px-2 py-1 truncate uppercase">{{ o?.address?.state || '' }}</td>
                                <td class="border truncate"><button @click="editingOwner = o" class="w-full px-2 py-1 flex-grow">{{ o?.ownerInfo?.firstName || '' }}&nbsp;{{ o?.ownerInfo?.lastName || '' }}</button></td>
                                <td class="border px-2 py-1 truncate text-2xs">{{ o?.status || '' }}</td>
                                <td class="border truncate text-2xs"><button @click="editingRevenue = o" class="w-full px-2 py-1 flex-grow">{{ parseRevenue(o?.revenue || 0) ? parseRevenue(o?.revenue)?.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }) : '' }}</button></td>
                                <td class="border px-2 py-1">
                                    <div class="flex justify-center"><Toggle
                                        @update:modelValue="saveOpportunity(o, 'installationStarted')"
                                        :modelValue="!!o.installationStarted?.value"
                                        :small="true"
                                        background="bg-gray-100 dark:bg-gray-700"
                                    /></div>
                                </td>
                                <!-- <td class="border border-r-0 px-2 py-1">
                                    <div class="flex justify-center"><Toggle
                                        @update:modelValue="saveOpportunity(o, 'installationCompleted')"
                                        :modelValue="!!o.installationCompleted?.value"
                                        :small="true"
                                        background="bg-gray-100 dark:bg-gray-700"
                                    /></div>
                                </td> -->
                                <td class="border px-2 py-1 truncate text-2xs text-center">{{ dayjs(o?.updated).format('MM/DD h:mma') }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            <!-- </div> -->
            </Mango>
        </div>
        <div class="w-full h-[8rem] bg-white block shrink-0" style="height: 128px;">
            <div class="fixed md:relative bottom-0 right-0 flex justify-center w-full">
                <Spinner :class="loadingPage ? 'opacity-100' : 'opacity-0'" :small="true" class="w-16 h-16 my-8 shrink-0 sm:mx-auto transition-opacity self-start" />
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import Hero from '../components/layout/hero.vue'
import Mango from '../helpers/mango'
import dayjs from 'dayjs'
import Toggle from '../components/partials/toggle.vue'
import Modal from '../components/layout/modal.vue'
import MultiSelect from '../helpers/multiSelect.vue'
// import VagueAddress from '../../../front/src/components/layout/vagueAddress.vue'
import downloadCsv from '../helpers/download-csv.js'
import Table from '../helpers/table/table.vue'
import People from '../components/layout/people.vue'

let colorStatusMap = {
    closed: 'text-red-800 bg-red-50',
    Lockout: 'text-red-800 bg-red-50',
    open: 'text-green-800 bg-green-50',
    featured: 'text-green-800 bg-green-50',
    'Insurance Accepted': 'text-green-800 bg-green-50',
    'Contract Signed': 'text-green-800 bg-green-50',
    Contingency: 'text-green-800 bg-green-50',
    pending: 'text-yellow-800 bg-yellow-50',
    'Soft Set': 'text-yellow-800 bg-yellow-50',
    hidden: 'text-orange-800 bg-orange-50',
    Retail: 'text-orange-800 bg-orange-50',
    Skip: '',
    'No Answer': '',
    'Not Interested': ''
}

let baseFilters = {
    rep: [],
    branch: [],
    streetNumber: null,
    street: null,
    city: null,
    owner: null,
    contractSigned: null,
    insuranceAccepted: null,
    revenueMin: null,
    revenueMax: null,
    estimateMin: null,
    estimateMax: null,
    address: {
        formatted: null
    },
    rangeStart: dayjs().subtract(3, 'months').format('YYYY-MM-DD'),
    rangeEnd: dayjs().format('YYYY-MM-DD'),
}

export default {
  components: { Hero, Toggle, Modal, MultiSelect, Table, People },
    props: ['collection'],
    inject: ['axios','store'],
    data() {
        return {
            search: null,
            selectedPhoto: null,
            editingOwner: null,
            editingRevenue: null,

            data: [],
            repEntries: [],
            branchEntries: [],
            opportunitiesBeingSaved: [],

            baseFilters,
            loading: true,
            loadingPage: false,
            downloading: false,
            filters: JSON.parse(JSON.stringify(baseFilters)),

            colorStatusMap,
        }
    },
    computed: {
        algoliaSearch() { return this.collectionModel?.algolia === false ? null : this.search },
        collectionModel() {
            let inferedCollection = this.collection || this.$route.path.split('/')?.[1] || null
            let validatedCollection = Mango.collections.find(c => c.name == inferedCollection)
            if (!validatedCollection) return console.error(`🥭 ${inferedCollection} is not a valid collection.`)
            return validatedCollection
        },
        admin() {
            return this.store?.user?.roles?.includes('admin')
        },
        branches() {
            if (!this.admin) {
                return this.store?.user?.branches || []
            }
        },
        query() {
            if (this.algoliaSearch) return
            let query = {limit: 50, search: {}, sort: {created: -1}}
            // if (this.collectionModel?.adminIndex) query.fields = [...query.fields, ...this.collectionModel.adminIndex]
            // if (this.search && !this.collectionModel.algoliaModel) query.search.wordSearch = this.search
            query.fields = ['shotgun','author','status','selfie','ownerInfo','address','created','updated','installationStarted','installationCompleted','revenue']
            if (Array.isArray(this.collectionModel?.adminIndex)) {
                query.fields = [...query.fields, ...this.collectionModel.adminIndex]
            }
            if (this.branches) {
                if (this.collectionModel.name == 'members') query.search.branches = {$in: this.branches.map(b => b.id)}
                else query.search.branch = {$in: this.branches.map(b => b.id)}
            }

            if (this.filterSearch) query.search = {...query.search, ...this.filterSearch}
            return query
        },
        algoliaFilters() {
            if (this.algoliaSearch && this.branches) {
                if (this.collectionModel.name == 'members') {
                    return this.branches.map(b => `branches:${b.id} OR branches.id:${b.id}`).join(' OR ')
                } else {
                    return this.branches.map(b => `branch:${b.id} OR branch.id:${b.id}`).join(' OR ')
                }
            }
        },
        branchSearchFilters() {
            if (this.branches) {
                return this.branches.map(branch => `objectID:${branch.id}`).join(' OR ');
            }
        },
        repSearchFilters() {
            if (!this.branches) return
            let algoliaFilters = this.branches.map(branch => `branches.id:${branch.id}`).join(' OR ');
            return algoliaFilters
        },
        filterSearch() {
            if (this.collectionModel.name != 'opportunities') return
            let search = {}
            if (this.filters.rep?.length) search.$or = [{author: {$in: this.filters.rep}},{shotgun: {$in: this.filters.rep}}]
            if (this.filters.branch?.length) search.branch = {$in: this.filters.branch}
            if (this.filters.address?.id) search['address.id'] = this.filters.address.id
            if (this.filters?.streetNumber) search['address.number'] = this.filters.streetNumber
            if (this.filters?.street) search['address.street'] = {$regex: this.filters.street, $options: 'i'}
            if (this.filters?.city) search['address.city'] = {$regex: this.filters.city, $options: 'i'}
            if (this.filters?.owner) search['ownerInfo.lastName'] = {$regex: this.filters.owner, $options: 'i'}
            if (this.filters?.contractSigned) search.contractSigned = {$ne: null}
            if (this.filters?.insuranceAccepted) search.insuranceAccepted = {$ne: null}
            if (this.filters?.installationStarted) search['installationStarted.value'] = {$ne: false, $exists: true}
            if (this.filters?.installationCompleted) search['installationCompleted.value'] = {$ne: false, $exists: true}
            if (this.filters?.revenueMin) search.revenue = {$gte: this.filters.revenueMin}
            if (this.filters?.revenueMax) search.revenue = {...(search?.revenue || {}), $lte: this.filters.revenueMax}
            if (this.filters?.estimateMin) search.estimate = {$gte: this.filters.estimateMin}
            if (this.filters?.estimateMax) search.estimate = {...(search?.estimate || {}), $lte: this.filters.estimateMax}
            if (this.filters?.rangeStart) search.compareCreated = {greaterThan: dayjs(this.filters.rangeStart)}
            if (this.filters?.rangeEnd) search.compareCreated ? search.compareCreated.lessThan = dayjs(this.filters.rangeEnd).add(1, 'day') : {lessThan: dayjs(this.filters.rangeEnd).add(1, 'day')}
            return search
        },
    },
    methods: {
        dayjs,
        setData(entries) {
            // let existingIds = this.data.map(e => e.id)
            // this.data = this.data.concat(entries.filter(e => !existingIds.includes(e.id)))
            this.data = entries
        },
        async exportOpportunities() {
            this.downloading = true
            let query = {...this.query}
            query.fields = ['address','title','status','revenue','estimate','created','updated','contractSigned','insuranceAccepted','shotgun','ownerInfo','author']
            query.limit = 10000
            let opportunities = await Mango.opportunities(query)
            let csvContent = "data:text/csv;charset=utf-8,"
                + "Owner Name,Address,City,State,Status,Revenue,Reps,Created,Updated\n"
                + opportunities?.map(o => {
                    return `
                        ${(`${o?.ownerInfo?.firstName || ''} ${o.ownerInfo?.lastName || 'No Name'}`)?.trim?.()?.replace?.(/[^a-zA-Z0-9\s\-]/g, '')},
                        ${o.address?.address?.trim?.()?.replace?.(/[^a-zA-Z0-9\s\-]/g, '') || ''},
                        ${o.address?.city?.trim?.()?.replace?.(/[^a-zA-Z0-9\s\-]/g, '') || ''},
                        ${o.address?.state?.trim?.()?.replace?.(/[^a-zA-Z0-9\s\-]/g, '') || ''},
                        ${o.status || ''},
                        $${o.revenue || '0'},
                        ${[...(o?.shotgun || []), o.author]?.map(s => s?.firstName + ' ' + s?.lastName).join(' / ') || ''},
                        ${dayjs(o.created).format('MMM. D YYYY')},
                        ${dayjs(o.updated).format('MMM. D YYYY')}
                    `.replace(/\n/g, '').replace(/\s+/g, ' ').replaceAll('", "', '","')
                }).join('\n')
            downloadCsv(csvContent, `Opportunities.csv`)
            this.downloading = false
        },
        clearFilters() {
            this.filters = JSON.parse(JSON.stringify(baseFilters))
            this.branchEntries = []
            this.showFilters = false
        },
        sort(field) {
            return
            if (!this.query?.sort) this.query.sort = {}
            // if (this.query.sort?.[field])
            this.query.sort[field] = this.query.sort?.[field] === 1 ? -1 : 1
            for (let key in this.query.sort) {
                if (key != field) delete this.query.sort[key]
            }
        },
        async remove(entry) {
            this.working = true
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let response = await Mango[this.collectionModel.name].delete(entry.id)
                    if (response.deleted == 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your entry has been deleted.',
                            'success'
                        )
                        this.working = false
                        this.data.splice([this.data.findIndex(e => e.id == entry.id)], 1)
                    } else {
                        Swal.fire(
                            'Error!',
                            response?.response,
                            'error'
                        )
                        this.working = false
                    }
                } else {
                    this.working = false
                }
            })
        },
        async duplicate(entry) {
            this.working = true
            let newEntry = (await this.axios.get(`${this.store.api}/${this.collectionModel.name}/${entry.id}?depthLimit=0`))?.data?.response
            console.log('newEntry', newEntry)
            if (!newEntry.id) {
                this.working = false
                return Swal.fire(
                    'Error!',
                    'There was an error duplicating this entry.',
                    'error'
                )
            }

            delete newEntry.id
            delete newEntry._id
            delete newEntry.algoliaId
            delete newEntry.collection

            for (let field of (this.collectionModel.fields.filter(f => f.computed || f.relationship) || [])) {
                delete newEntry[field.name]
            }

            for (let field in newEntry) {
                if (!this.collectionModel.fields.some(f => f.name == field)) {
                    delete newEntry[field]
                }
            }

            let response = (await this.axios.post(`${this.store.api}/${this.collectionModel.name}`, newEntry))?.data?.response

            if (!response.id) {
                this.working = false
                return Swal.fire(
                    'Error!',
                    'There was an error duplicating this entry.',
                    'error'
                )
            }

            Swal.fire(
                'Duplicated!',
                'Your document has been duplicated.',
                'success'
            )

            this.data.unshift(response)
            this.working = false
        },
        parseRevenue(revenueString) {

            if (!isNaN(revenueString)) return Number(revenueString)

            // Remove non-numeric characters from the string
            const numericString = revenueString.replace(/[^0-9]/g, '');

            // Parse the numeric string to an integer
            const revenue = parseInt(numericString, 10);

            // Check if it's a valid integer, if not, return 0 or handle the error as needed
            if (!isNaN(revenue)) {
                return revenue;
            } else {
                return 0; // You can choose to return a default value or handle the error differently
            }
        },
        async saveOpportunity(o, changed) {

            console.log('saving')
            this.opportunitiesBeingSaved.push(o.id)
            if (!o[changed]?.value && !o.revenue) {
                Swal.fire({icon: 'warning', title: 'Error', text: 'Please enter a revenue amount before starting the installation.'})
                return this.editingRevenue = o
            }
            o[changed] = o[changed]?.value ? {value: false} : {value: true}
            let response = await Mango.opportunities.save({id: o.id, [changed]: o[changed].value})
            if (response?.includes?.('(opportunity)')) {
                Swal.fire({icon: 'warning', title: 'Error', text: response.split(') ')[1]})
            }
            this.opportunitiesBeingSaved = this.opportunitiesBeingSaved.filter(id => id != o.id)

        },
        async saveOwnerInfo(o) {
            let opportunity = JSON.parse(JSON.stringify(o))
            this.editingOwner = null
            this.opportunitiesBeingSaved.push(opportunity.id)
            await Mango.opportunities.save({id: opportunity.id, ownerInfo: opportunity.ownerInfo})
            this.opportunitiesBeingSaved = this.opportunitiesBeingSaved.filter(id => id != opportunity.id)
        },
        async saveRevenue(o) {
            let opportunity = JSON.parse(JSON.stringify(o))
            this.editingRevenue = null
            this.opportunitiesBeingSaved.push(opportunity.id)
            await Mango.opportunities.save({id: opportunity.id, revenue: this.parseRevenue(opportunity.revenue)})
            this.opportunitiesBeingSaved = this.opportunitiesBeingSaved.filter(id => id != opportunity.id)
        }
    }
}
</script>

<style lang="postcss" scoped>
input {
    @apply border border-gray-300 outline-blue-400 dark:outline-blue-500/40 px-2 py-1 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-600 w-full
}
</style>
