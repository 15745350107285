<template>

    <label class="relative">
        <div class="">
            {{ value ? dayjs(value).format('h:mma') : '' }}
        </div>
        <input
            type="datetime-local"
            @change="save(new Date($event.target.value))"
            :value="dayjs(value).format('YYYY-MM-DDTHH:mm')"
            class="dark:bg-transparent absolute inset-0 w-full h-full"
        >
    </label>

</template>

<script>
import dayjs from 'dayjs'
import {useModel} from '../../model'

export default {
    props: ['modelValue'],
    methods: {
        dayjs,
        save(v) {
            this.value = v
            this.$emit('valueToSubmit', v)
        },
    },
	setup(props, { emit }) {
		return {
            value: useModel(props, emit, 'modelValue'),
		}
	},
}
</script>

<style scoped>
input[type="datetime-local"] {
    display: inline-block;
    opacity: 0;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
</style>
