<template>
    <div class="bg-transparent">
        <select v-model="value" @change="save" class="bg-transparent w-full outline-none">
            <option :value="null" :selected="value == null"></option>
            <option :value="option.toLowerCase()" v-for="option in fieldModel.options" :key="option" :selected="value == option">{{ option }}</option>
        </select>
    </div>
</template>

<script>
import {useModel} from '../../model'

export default {
    props: ['modelValue', 'fieldModel'],
    methods: {
        save() {
            this.$emit('valueToSubmit', this.value)
        },
    },
	setup(props, { emit }) {
		return {
            value: useModel(props, emit, 'modelValue'),
		}
	},
}
</script>

<style>

</style>
