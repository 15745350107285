<template>
    <div ref="parent" class="relative" :style="`width: var(${columnId});`">
        <slot />
        <div class="dragbar" @mousedown="handleDragDown" />
    </div>
</template>

<script>
export default {
    props: ['columnId', 'container'],
    data() {
        return {
            dragging: 0,
            default: 300
        }
    },
    created() {
        let savedWidth = window.localStorage.getItem(this.columnId)
        document.body.style.setProperty(this.columnId, (savedWidth || this.deafult || '150') + 'px');
    },
    methods: {
        resize(e) {
            let parentLeft = this.$refs.parent.offsetLeft
            let containerLeft = this.$refs.parent.offsetParent.offsetLeft
            let draggedDifference = e.pageX - parentLeft - (containerLeft || 0)
            if (draggedDifference > 1000 || draggedDifference < 10) { return }
            document.body.style.setProperty(this.columnId, draggedDifference + 'px');
            window.localStorage.setItem(this.columnId, draggedDifference)
        },
        clearJSEvents() {
            this.dragging = 0;
            document.body.removeEventListener("mousemove", this.resize);
            document.removeEventListener("mouseup", this.resize);
            document.body.classList.remove('resizing');
        },
        handleDragDown(e) {
            // console.log('dragging down')
            document.onmouseup = this.handleDragUp
            e.preventDefault();
            this.dragging = 1;
            document.body.addEventListener('mousemove', this.resize);
            document.body.classList.add('resizing');
        },
        handleDragUp() {
            // console.log('dragging up')
            this.dragging ? this.clearJSEvents() : '';
        }
    },
}
</script>

<style>
body.resizing {
  cursor: col-resize;
}

.dragbar {
  top: 0;
  height: 100%;
  background: white;
  position: absolute;
  right: 0;
  width: 5px;
  opacity: 0;
  cursor: col-resize;
  background: #ffffff73;
  transition: 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s;
}

.dragbar:hover,
body.resizing .dragbar {
  opacity: 1;
  transition: 0.3s ease-in-out 0s, opacity 0.3s ease-in-out .3s;
}
</style>
