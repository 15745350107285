<template>
    <div v-if="collectionModel" :class="{'pointer-events-none opacity-40': saving}">
        <Mango @update:data="setEntry" v-if="$route.params?.id" :suspend="true" :main="true" :id="$route.params?.id" :collection="collectionModel.name" />
        <Hero
            :title="entry.title || `New ${collectionModel.humanSingular}`"
            :subtitle="null"
            class="border-b-4 dark:border-gray-800"
            :image="heroImage"
        >
            <template v-if="collectionModel.name == 'members'" #subtitle>
                <a @click.stop :href="`/members/${entry.id}/report`" target="_blank" class="hover:text-sky-500 underline">
                    View Rep Report
                </a>
            </template>
            <template v-if="collectionModel.name == 'opportunities' && entry.jnid" #subtitle>
                <div class="flex space-x-4 items-center justify-center pt-4">
                    <div>
                        <a
                            :href="`https://app.jobnimbus.com/contact/${entry.jnid}`"
                            target="_blank" class="border border-[#314f8f] rounded text-[#314f8f] px-3 py-2"
                        >
                            View in JobNimbus
                            <!-- <img src="https://webappui.jobnimbus.com/images/favicon.png?" class="w-6 block" /> -->
                        </a>
                    </div>

                    <div v-if="['it.support@bestchoiceroofing.com','cneifert@ncfic.org'].includes(store.user.email)" class="">
                        <a :href="`/opportunities/${entry.id}/estimate`" target="_blank" class="border border-orange-500 rounded text-orange-500 px-3 py-2">Material/Work Order</a>
                    </div>
                </div>
            </template>
        </Hero>
        <div v-if="($route.params?.id && entry?.id) || !$route.params?.id" class="w-full flex flex-col-reverse 2xl:flex-row justify-center items-center 2xl:items-start p-8 2xl:p-16 2xl:space-x-16 relative">
            <div class="w-full max-w-xl flex flex-col space-y-6 mt-16 2xl:mt-0 border-t dark:border-gray-800 pt-16 2xl:pt-0 2xl:border-0">
                <div v-for="field in mutableFields" :key="field.name" class="w-full">
                    <Field :field="field" v-model="entry[field.name]" :entry="entry" :collection="collectionModel" />
                </div>
            </div>
            <div class="w-full max-w-xl flex flex-col space-y-6 2xl:sticky top-24">

                <div v-for="field in globalFields" :key="field.name" class="w-full">
                    <Field :field="field" v-model="entry[field.name]" :entry="entry" :collection="collectionModel" />
                </div>

                <div class="" v-if="entry.author?.length">

                    <div class="uppercase text-xs font-semibold">Author</div>
                    <div class="px-4 py-2 mt-2 bg-gray-100 dark:bg-gray-800 block rounded-lg flex items-center space-x-4">
                        <div
                            v-if="entry.author[0]?.image?.url"
                            class="rounded-full bg-cover bg-center bg-gray-500 shrink-0 w-12 h-12 border-2 border-gray-500/50"
                            :style="`background-image: url(${entry.author[0]?.image?.url})`"
                        />
                        <div>
                            <router-link :to="`/members/${entry.author[0].id}`">
                                <div class="hover:underline">{{ entry.author[0].title }}</div>
                            </router-link>
                            <div class="text-xs">{{ entry.author[0].email }}</div>
                        </div>
                        <div class="flex flex-grow justify-end">
                            <a v-if="collectionModel.name == 'opportunities'" v-tooltip="`View Report`" @click.stop :href="`/members/${entry?.author?.[0]?.id}/report`" target="_blank" class="hover:text-sky-500">
                                <i class="fad fa-scroll" />
                            </a>
                        </div>
                    </div>
                </div>
                <div>

                    <div class="uppercase text-xs font-semibold mt-6">Details</div>
                    <div class="space-y-3 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg mt-2">
                        <div class="w-full flex justify-between items-center text-sm">
                            <div class="uppercase text-xs font-semibold">Created:</div>
                            <div class="flex-grow mx-2 border-b border-dotted border-gray-500/50" />
                            <div>{{ dayjs(entry.created).format('MM/DD/YYYY hh:mm:ssa') }}</div>
                        </div>
                        <div class="w-full flex justify-between items-center text-sm">
                            <div class="uppercase text-xs font-semibold">Updated:</div>
                            <div class="flex-grow mx-2 border-b border-dotted border-gray-500/50" />
                            <div>{{ dayjs(entry.updated).format('MM/DD/YYYY hh:mm:ssa') }}</div>
                        </div>
                    </div>

                </div>

                <div class="flex justify-between">
                    <button @click="save" class="px-3 py-2 rounded bg-red-500 text-white">Save</button>
                    <button
                        v-if="collectionModel.name != 'members' && collectionModel.name != 'branches'"
                        @click="deleteEntry"
                        class="text-gray-500 underline"
                    >
                        Delete
                    </button>
                </div>

                <div v-if="entry?._versions?.length" class="">
                    <div class="mb-2">Change Log:</div>
                    <div class="border-2 p-2 text-xs divide-y overflow-x-scroll">
                        <div v-for="v in entry._versions.filter(v => !v.original).sort((a,b) => a.updated < b.updated ? 1 : -1)" class="p-2">
                            <!-- <div>{{ dayjs(v.updated).format() }}</div> -->
                            <pre class="">{{ v }}</pre>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import Hero from '../components/layout/hero.vue'
import Field from './Field.vue'
import Mango from './mango'
import dayjs from 'dayjs'

export default {
  components: { Hero, Field },
    props: ['collection'],
    inject: ['axios','store'],
    data() {
        return {
            keyListener: null,
            saving: false,
            collectionModel: null,
            entry: {},
            originalEntry: {},
            hiddenFields: [
                'originalRawAddress',
                'lastSavedStep',
                'timestamps',
                'cePending',
                'dailySent',
                'salesOpportunitiesSent',
                'company',
                'team',

                'mtd',
                'opportunities',
                'tracking',
                'addressLockout',
                'branchReports',
                'repReports',

                'installationsStarted',
                'startedInstallationsRevenue',
                'installationsCompleted',
                'completedInstallationsRevenue',

                'entryId',
                'channelId',
                'author',
                'authorId',
                'channelName',
                'algoliaId',
                'parlerId',
                'facebookId',
                'urlTitle',
                'hits',
                'collection',
                'length',
                '_id'
            ]
        }
    },
    created() {
        if (this.$route?.query?.message == 'lockout') {
            Swal.fire({icon: 'success', text: `You have successfully approved this address lockout.`, title: `Address Lockout Approved!`})
        }
        if (!this.store?.user?.roles?.includes('admin')) {
            this.hiddenFields.push('branch')
        }
        let inferedCollection = this.collection || this.$route.path.split('/')?.[1] || null
        let validatedCollection = Mango.collections.find(c => c.name == inferedCollection)
        if (!validatedCollection) console.error(`🥭 ${collection} is not a valid collection.`)
        else this.collectionModel = validatedCollection

        if (inferedCollection == 'branchReports') {
            this.hiddenFields.push('email')
        }
    },
    mounted() {
        this.keyListener = function(e) {
            if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
                e.preventDefault(); // present "Save Page" from getting triggered.
                this.save()
            }
        };
        document.addEventListener('keydown', this.keyListener.bind(this));
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.keyListener);
    },
    methods: {
        dayjs,
        setEntry(entry) {

            if (!entry?.id) return this.$router.push(`/${this.collectionModel.name}`)

            this.heroTitle = entry?.address?.address
            this.heroImage = entry?.selfie?.url || entry?.image?.url

            for (let fieldName in entry) {

                let fieldModel = this.collectionModel.fields?.find(f => f.name == fieldName)
                if (fieldModel?.relationship) {
                    if (!Array.isArray(entry[fieldName])) {
                        if (entry[fieldName]?.id) entry[fieldName] = [entry[fieldName]]
                        else entry[fieldName] = []
                    }
                }

            }

            this.entry = entry
            this.originalEntry = JSON.parse(JSON.stringify(entry))
            setTimeout(() => {
                this.$forceUpdate()
            }, 500);

        },
        computeTimestamps(entry, field) {

            let prefix = null
            if (field.includes('.')) {
                prefix = field.split('.')[0]
                field = field.split('.')[1]
            }

            let value = prefix ? entry[prefix]?.[field] : entry[field]
            let originalValue = prefix ? this.originalEntry[prefix]?.[field] : this.originalEntry[field]

            console.log('value, originalValue', value, originalValue, prefix)

            if (value != originalValue) {
                if (['knock','talk','walk','contingency','revenue','estimate'].includes(field)) {
                    entry.timestamps[field] = value ? new Date() : null
                } else {
                    entry[field] = value ? new Date() : null
                }
            }

        },
        async save() {

            // Force LastName
            if (this.collectionModel.name == 'opportunities' && this.entry.ktwc?.contingency && !this.entry.ownerInfo?.lastName) {
                return Swal.fire({icon: 'warning', text: `Please enter a last name for the owner.`})
            }

            // let entryCopy = {...this.entry}
            let entryCopy = JSON.parse(JSON.stringify(this.entry))
            let timestamps = entryCopy?.timestamps || {}

            for (let fieldName in entryCopy) {

                let fieldModel = this.collectionModel.fields?.find(f => f.name == fieldName)
                if (!fieldModel) {
                    delete entryCopy[fieldName]
                    continue
                }

                if (this.hiddenFields.includes(fieldName)) delete entryCopy[fieldName]

                if (fieldName[0] == '_') delete entryCopy[fieldName]
                if (fieldModel?.computed) delete entryCopy[fieldName]

                if (fieldModel?.translate && entryCopy[fieldName] == null) {
                    delete entryCopy[fieldName]
                }

            }

            // Manage timestamps and toggles for opportunities
            if (this.collectionModel.name == 'opportunities') {

                if (!isNaN(entryCopy.revenue) && Number(entryCopy.revenue) > 2500000) return Swal.fire({icon: 'warning', title: 'Revenue Limit Reached', text: 'Revenue cannot be greater than $2.5M'})

                entryCopy.timestamps = timestamps
                console.log('entryCopy.timestamps', entryCopy.timestamps)

                let timestampFields = [
                    'ktwc.knock',
                    'ktwc.talk',
                    'ktwc.walk',
                    'ktwc.contingency',
                    'revenue',
                    'estimate',
                    'insuranceAccepted',
                    'contractSigned',
                    'addressLockout',
                    'installationStarted',
                    'installationCompleted',
                ]

                for (let field of timestampFields) {
                    this.computeTimestamps(entryCopy, field)
                }
                console.log('entryCopy.timestamps', entryCopy.timestamps)

                if (entryCopy?.inspection?.toLowerCase?.() == 'contingency' && entryCopy?.inspection != this.originalEntry?.inspection) {
                    entryCopy['ktwc.contingency'] = 1
                    entryCopy.timestamps.contingency = new Date()
                }
                if (entryCopy?.inspection?.toLowerCase?.() == 'soft set' && entryCopy?.inspection != this.originalEntry?.inspection) entryCopy.timestamps.softSet = new Date()
                if (entryCopy?.discussion?.toLowerCase?.() == 'soft set' && entryCopy?.discussion != this.originalEntry?.discussion) entryCopy.timestamps.softSet = new Date()

                // Reassign author based on shotgun
                let authorId = this.entry.author?.[0]?.id
                console.log('authorId', authorId)
                if (entryCopy?.shotgun?.length && !entryCopy.shotgun.find(s => s == authorId)) {
                    entryCopy.author = [entryCopy.shotgun[0]]
                    entryCopy.authorId = entryCopy.shotgun[0]?.id || entryCopy.shotgun[0]
                }
                entryCopy.shotgun = entryCopy.shotgun?.filter(s => s != entryCopy.author?.[0])

            }


            let id = this.entry?.id || ''

            // Make sure branch managers always add things to their own branches
            if (this.branch) entryCopy.branch = this.branch

            this.saving = true
            console.log('entryCopy', entryCopy)
            let response = await Mango[this.collectionModel.name].save({id, ...entryCopy})
            // let response = await this.axios[method](`https://api.churchandfamilylife.com/${this.collectionModel.name}/${id}`, entryCopy)
            // let response = await this.axios[method](`http://localhost:3002/${this.collectionModel.name}/${id}`, entryCopy)
            this.saving = false
            let success = response?.id

            if (!success) return new Swal({icon: 'error', text: `${response}`})
            // this.entry = {...this.entry, ...response?.data?.response}
            new Swal({icon: 'success', text: `Your entry was saved successfully.`})
            if (!id) this.$router.push(success)

        },
        async deleteEntry() {
            let approved = (await Swal.fire({
                title: `Are You Sure?`,
                confirmButtonText: 'Delete',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                icon: 'warning',
                html: `Do you really want to delete <em>${this.entry.title}</em> irrevocably always and forever?`
            })).isConfirmed
            if (approved) {
                this.$emit('deleted', true)
                let response = await Mango[this.collectionModel.name].delete(this.entry)
                if (response?.deleted < 1) return Swal.fire({title: 'Error', icon: 'error', text: 'Something went wrong'})
                new Swal({icon: 'success', html: `Your entry <em>${this.entry.title}</em> was deleted successfully.`})
                this.$router.push(`/${this.entry.collection}`)
            }
        },
    },
    computed: {
        branch() {
            if (this.store?.user?.roles?.includes('branchManager')) {
                return this.store.user.branch?.id || this.store.user.branch
            }
        },
        mutableFields() {
            return this.collectionModel.fields.filter(f => !f.computed && !this.hiddenFields.includes(f.name) && !f.global)
        },
        globalFields() {
            return this.collectionModel.fields.filter(f => !f.computed && !this.hiddenFields.includes(f.name) && f.global)
        },
        price() {
            return this.collectionModel.fields.filter(f => f.name == 'price')[0]
        }
    }
}
</script>

<style lang="postcss" scoped>
input, label {
    @apply border border-gray-300 rounded outline-blue-400 dark:outline-blue-500/40 px-3 py-2 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-600
}
select {
    @apply dark:bg-transparent
}
</style>
